import { Button, Flex, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { createSelector } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useAppSelector } from 'src/hooks/redux';
import { useGetReportPageFiltersMutation } from 'src/redux/api';
import {
    selectActiveReportPage,
    selectAppliedFilters,
    selectDateRangeComponent
} from 'src/redux/features/blueprint/bluePrintSlice';

import { ComponentType } from 'common/enums';
import { Component } from 'common/types';
import UIComponent from 'src/templates/blueprint/UIComponent';

import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import KeyboardShortcutsModal from 'src/components/KeyboardShortcutsModal';
import Canvas from 'src/templates/blueprint/Canvas/Canvas';
import AlignToolbar from 'src/templates/blueprint/toolbars/AlignToolbar';
import { getFilterComponents } from 'src/templates/blueprint/utils';
import { useFetchReportPageData } from 'src/hooks/useFetchReportPageData';
import React from 'react';

export const Root = () => {
    const { state, actions } = useCanvas();
    const activeReportPage = useAppSelector(selectActiveReportPage);
    const fetchPageData = useFetchReportPageData();
    const [fetchPageFilterOptions] = useGetReportPageFiltersMutation();
    const dateRangeFilter = useAppSelector(selectDateRangeComponent);

    const selectMemoStart = createSelector(
        [selectAppliedFilters],
        (filters) => filters?.find((f) => f.id === dateRangeFilter?.id)?.value?.start
    );
    const selectMemoEnd = createSelector(
        [selectAppliedFilters],
        (filters) => filters?.find((f) => f.id === dateRangeFilter?.id)?.value?.end
    );
    const startDate = useAppSelector((state) => selectMemoStart(state));
    const endDate = useAppSelector((state) => selectMemoEnd(state));

    useEffect(() => {
        if (activeReportPage?.id && state) {
            fetchPageData({
                isSharedReport: state.useSharedReportAPI
            });
        }
    }, [activeReportPage?.id]);

    useEffect(() => {
        if (activeReportPage && Boolean(startDate) && Boolean(endDate)) {
            fetchPageFilterOptions({
                reportPageId: activeReportPage.id,
                filterIds: getFilterComponents(activeReportPage.components).map(
                    (f) => f.id
                ),
                dateRange: {
                    start: startDate,
                    end: endDate
                },
                isSharedReport: state.useSharedReportAPI
            });
        }
    }, [activeReportPage]);

    const components: Component[] = Object.values(activeReportPage?.components ?? {});

    return (
        <React.Fragment>
            {state.useEditorReport && (
                <Flex justifyContent={'space-between'}>
                    <Menu>
                        <MenuButton
                            mb="1rem"
                            variant="solid"
                            colorScheme="additional"
                            as={Button}
                        >
                            Add component
                        </MenuButton>
                        <MenuList zIndex={100}>
                            {Object.values(ComponentType).map((type) => (
                                <MenuItem
                                    key={type}
                                    onClick={() => actions.addComponent(type)}
                                >
                                    {type}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                    <AlignToolbar />
                </Flex>
            )}
            {activeReportPage && (
                <Canvas layout={activeReportPage.layout}>
                    {components.map((item) => (
                        <UIComponent key={item.id} component={item} />
                    ))}
                </Canvas>
            )}
            <KeyboardShortcutsModal />
        </React.Fragment>   
    );
};
