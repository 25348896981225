import { z } from 'zod';
import { ComponentType, LayoutScale } from '../enums.js';
import { getData, getFilterOptions } from './getDataValidator.js';
import validator from 'validator';
const dateString = z.string().refine((val) => {
    const date = new Date(val);
    return !isNaN(date.getTime());
}, {
    message: 'Invalid date string'
});
const baseComponent = z.object({
    id: z.string(),
    x: z.number(),
    y: z.number(),
    w: z.number(),
    h: z.number(),
    zIndex: z.number().optional(),
    type: z.nativeEnum(ComponentType),
    properties: z
        .object({
        style: z.record(z.any())
    })
        .catchall(z.any())
});
const dimensionConfig = z.object({
    id: z.string(),
    customDisplayName: z.string().optional().nullable(),
    customDecimalPlaces: z.number().optional().nullable()
});
const metricConfig = z.object({
    id: z.string(),
    customDisplayName: z.string().optional().nullable(),
    customDecimalPlaces: z.number().optional().nullable()
});
const dataComponent = baseComponent.extend({
    type: z.enum([
        ComponentType.TABLE,
        ComponentType.LINE_CHART,
        ComponentType.PIE_CHART,
        ComponentType.SINGLE_VALUE,
        ComponentType.DONUT_CHART,
        ComponentType.AREA_CHART,
        ComponentType.BIAXIAL_BAR_CHART,
        ComponentType.STACKED_BAR_CHART,
        ComponentType.TINY_BAR_CHART,
        ComponentType.TABLE_PROGRESS_BAR_CHART
    ]),
    dimensions: z.array(dimensionConfig).optional().nullable(),
    metrics: z.array(metricConfig).optional().nullable(),
    connectedSourceId: z.number().optional().nullable(),
    defaultFilterIds: z.array(z.number()).optional().nullable()
});
const dimensionFilterComponent = baseComponent.extend({
    type: z.enum([ComponentType.SINGLE_SELECT, ComponentType.MULTI_SELECT]),
    dimensionId: z.string().optional().nullable(),
    connectedSourceId: z.number().optional().nullable()
});
const dateRangeFilterComponent = baseComponent.extend({
    type: z.literal(ComponentType.DATE_RANGE),
    minDate: dateString.optional().nullable(),
    maxDate: dateString.optional().nullable(),
    dimensionId: z.string().optional().nullable(),
    connectedSourceId: z.number().optional().nullable()
});
const navigationButtonComponent = baseComponent.extend({
    type: z.literal(ComponentType.NAVIGATION_BUTTON),
    properties: baseComponent.shape.properties.extend({
        pageId: z.number().optional().nullable(),
        text: z.string(),
        url: z
            .string()
            .optional()
            .nullable()
            .superRefine((val, ctx) => {
            if (!val)
                return z.NEVER;
            if (val?.startsWith('mailto:')) {
                if (!validator.isEmail(val.replace('mailto:', ''))) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: 'Invalid email'
                    });
                    return z.NEVER;
                }
            }
            else if (val?.startsWith('tel:')) {
                if (!validator.isMobilePhone(val.replace('tel:', ''))) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: 'Invalid phone number'
                    });
                    return z.NEVER;
                }
            }
            else {
                if (val && !validator.isURL(val)) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: 'Invalid URL'
                    });
                }
            }
        })
    })
});
const imageComponent = baseComponent.extend({
    type: z.enum([ComponentType.IMAGE, ComponentType.LOGO]),
    properties: baseComponent.shape.properties.extend({
        src: z.string().optional(),
        alt: z.string().optional()
    })
});
const staticCompoennt = baseComponent.extend({
    type: z.enum([
        ComponentType.DOWNLOAD_BUTTON,
        ComponentType.RECTANGLE,
        ComponentType.TEXT,
        ComponentType.NAVBAR
    ])
});
const component = z.discriminatedUnion('type', [
    dataComponent,
    dimensionFilterComponent,
    dateRangeFilterComponent,
    staticCompoennt,
    navigationButtonComponent,
    imageComponent
]);
const layout = z.object({
    pageWidth: z.number().min(100, { message: 'Šírka stránky musí byť aspoň 100px' }),
    pageHeight: z.number().min(100, { message: 'Výška stránky musí byť aspoň 100px' }),
    background: z.string(),
    scale: z.enum([LayoutScale.FIXED, LayoutScale.FILL]),
    fontFamily: z.string().optional()
});
const update = z.object({
    id: z.number(),
    displayName: z.string().optional(),
    layout: layout.optional(),
    components: z.record(component).optional(),
    slug: z.string().optional(),
    isActive: z.boolean().optional()
});
const create = z.object({
    displayName: z.string(),
    reportId: z.number(),
    slug: z.string(),
    isActive: z.boolean().optional()
});
const duplicate = z.object({
    id: z.number()
});
const remove = z.object({
    id: z.number()
});
export default {
    component,
    baseComponent,
    dateRangeFilterComponent,
    dimensionFilterComponent,
    dataComponent,
    layout,
    update,
    staticCompoennt,
    create,
    remove,
    getData,
    getFilterOptions,
    navigationButtonComponent,
    imageComponent,
    duplicate
};
