import z from 'zod';
import { DataSourceIdentifier, Language } from '../enums.js';
import { defaultFilterConfig } from './defaultFilterValidator.js';
const baseReport = z.object({
    title: z.string().min(2, { message: 'Name must be at least 2 characters long' }),
    slug: z.string().min(2, { message: 'Slug must be at least 2 characters long' }),
    password: z
        .string()
        .min(6, { message: 'Password must be at least 6 characters long' })
        .max(20, { message: 'Password must be at most 20 characters long' })
        .nullable()
        .optional(),
    language: z.nativeEnum(Language).optional(),
    isActive: z.boolean().optional(),
    pageOrder: z.array(z.number()).nullable().optional(),
    isDraft: z.boolean().optional()
});
const templateCreate = baseReport.extend({
    isTemplate: z.literal(true),
    teamId: z.number().nullable().optional(),
    templatePublished: z.boolean().optional()
});
const normalCreate = baseReport.extend({
    isTemplate: z.literal(false).optional(),
    clientId: z.number()
});
const create = z.discriminatedUnion('isTemplate', [templateCreate, normalCreate]);
const createFromTemplate = baseReport.extend({
    templateId: z.number({ required_error: 'You have to choose a template.' }),
    clientId: z.number(),
    connectedSources: z.record(z.nativeEnum(DataSourceIdentifier), z.number()),
    pages: z.array(z.number()).min(1, { message: 'You have to choose at least one page.' })
});
const updateTemplate = baseReport.partial().extend({
    id: z.number(),
    isTemplate: z.literal(true),
    teamId: z.number().nullable().optional(),
    templatePublished: z.boolean()
});
const updateNormal = baseReport.partial().extend({
    id: z.number(),
    isTemplate: z.literal(false).optional(),
    uiTheme: z
        .object({
        components: z.record(z.any())
    })
        .optional()
});
const update = z.discriminatedUnion('isTemplate', [updateTemplate, updateNormal]);
const saveAsTemplate = z.object({
    id: z.number()
});
const list = z.object({
    teamId: z.number().optional(),
    clientId: z.number().optional(),
    isTemplate: z.boolean().optional()
});
const get = z.object({
    id: z.number()
});
const listDefaultFilters = z.object({
    reportId: z.number()
});
const getBySlug = z.object({
    slug: z.string(),
    clientId: z.number()
});
const remove = z.object({
    id: z.number()
});
const createDefaultFilter = z.object({
    reportId: z.number(),
    dataSourceId: z.number(),
    name: z.string(),
    config: defaultFilterConfig
});
const updateDefaultFilter = createDefaultFilter
    .omit({ dataSourceId: true, reportId: true })
    .extend({
    id: z.number()
});
const deleteDefaultFilter = z.object({
    id: z.number()
});
const updateImage = z.object({
    reportId: z.number()
});
const login = z.object({
    slug: z.string(),
    password: z.string().nullable().optional(),
    clientId: z.number()
});
const duplicate = z.object({
    reportId: z.number()
});
export default {
    create,
    update,
    updateImage,
    templateCreate,
    saveAsTemplate,
    baseReport,
    createFromTemplate,
    list,
    get,
    getBySlug,
    login,
    remove,
    createDefaultFilter,
    updateDefaultFilter,
    deleteDefaultFilter,
    listDefaultFilters,
    duplicate
};
