import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { defaultTheme } from 'common/constants';
import { ComponentType } from 'common/enums';
import { Component, DateRangeFilter, Layout, ReportPage } from 'common/types';
import { memoize } from 'lodash';
import { api } from 'src/redux/api';
import { RootState } from 'src/redux/store';
import { assertIsDefined, getInitDateRange } from 'src/templates/blueprint/utils';
import { Report } from './../../types/api';

export interface BluePrintState {
    componentToEditId?: string; // trackId
    activeReport?: Report;
    activeReportPageId?: number;
    hasChangedUI: boolean;
    activeReportPageData?: any;
    pageData?: any;
    pageFilters?: any;
    appliedFilters: {
        id: string;
        value: any;
    }[];
    isPageUpdating?: boolean;
}

const initialState: BluePrintState = {
    componentToEditId: undefined,
    activeReport: undefined,
    activeReportPageId: undefined,
    hasChangedUI: false,
    activeReportPageData: {},
    pageData: {},
    pageFilters: {},
    appliedFilters: [],
    isPageUpdating: false
};

const extractAppliedFiltersFromHeaders = () => {
    const userAgentValue = navigator.userAgent;
    const isPuppeteer = userAgentValue.includes('Puppeteer');
    const hasXFilters = userAgentValue.split('$').length === 2;
    if (isPuppeteer && hasXFilters) {
        try {
            const filters = JSON.parse(decodeURI(atob(userAgentValue.split('$')[1])));
            return {
                isPuppeteer,
                filters: filters as BluePrintState['appliedFilters']
            };
        } catch (error) {
            return {
                isPuppeteer,
                filters: [] as BluePrintState['appliedFilters']
            };
        }
    }
    return {
        isPuppeteer,
        filters: [] as BluePrintState['appliedFilters']
    };
};

const lazyInitialState = () => {
    const { isPuppeteer, filters } = extractAppliedFiltersFromHeaders();

    if (isPuppeteer) {
        return {
            ...initialState,
            appliedFilters: filters
        };
    } else {
        return initialState;
    }
};

export const bluePrintSlice = createSlice({
    name: 'blueprint',
    initialState: lazyInitialState(),
    reducers: {
        setActiveReport: (state, action: PayloadAction<Report>) => {
            state.activeReport = action.payload;
        },
        setActiveReportPages: (state, action: PayloadAction<Report['pages']>) => {
            if (!state.activeReport?.pages) {
                return;
            }

            state.activeReport.pages = action.payload;
        },

        setActiveReportPageId: (state, action: PayloadAction<number>) => {
            const page = state.activeReport?.pages?.find((p) => p.id === action.payload);

            const filtersIterable = Object.values(page?.components ?? {});

            const dateFilterConfiguration = filtersIterable.find(
                (f) => f.type === 'DATE_RANGE'
            ) as DateRangeFilter;

            if (dateFilterConfiguration) {
                const searchParams = new URLSearchParams(window.location.search);
                const urlSearchParamsDateRange = searchParams
                    .get(dateFilterConfiguration?.id)
                    ?.split('-');

                const startDateFromUrl =
                    urlSearchParamsDateRange?.length === 2
                        ? new Date(parseInt(urlSearchParamsDateRange[0]))
                        : undefined;

                const endDateFromUrl =
                    urlSearchParamsDateRange?.length === 2
                        ? new Date(parseInt(urlSearchParamsDateRange[1]))
                        : undefined;

                const initDateRange = getInitDateRange({
                    minDateString: dateFilterConfiguration?.minDate ?? null,
                    maxDateString: dateFilterConfiguration?.maxDate ?? null,
                    startDateFromUrl,
                    endDateFromUrl
                });

                // check if urlSearchParamsDateRange is not null and has 2 values

                const startDate = initDateRange.startDate;
                const endDate = initDateRange.endDate;

                state.appliedFilters = [
                    {
                        id: dateFilterConfiguration.id,
                        value: {
                            start: startDate.getTime(),
                            end: endDate.getTime()
                        }
                    }
                ];
            }

            state.activeReportPageId = action.payload;
        },
        setHasChangedUI: (state, action: PayloadAction<boolean>) => {
            state.hasChangedUI = action.payload;
        },
        setComponentConfig(
            state,
            action: PayloadAction<{ id: string; component: Component }>
        ) {
            if (!state.activeReport?.pages) {
                return;
            }

            state.activeReport.pages = state.activeReport.pages.map((p) => {
                if (p.id === state.activeReportPageId) {
                    return {
                        ...p,
                        components: {
                            ...p.components,
                            [action.payload.id]: action.payload.component
                        }
                    };
                }
                return p;
            });
        },
        setBulkComponentConfig(state, action: PayloadAction<Component[]>) {
            if (!state.activeReport?.pages) {
                return;
            }

            state.activeReport.pages = state.activeReport.pages.map((p) => {
                if (p.id === state.activeReportPageId) {
                    const components = action.payload.reduce(
                        (acc, component) => ({
                            ...acc,
                            [component.id]: component
                        }),
                        {}
                    );

                    return {
                        ...p,
                        components: {
                            ...p.components,
                            ...components
                        }
                    };
                }
                return p;
            });
        },
        deleteComponent(state, action: PayloadAction<{ id: string }>) {
            assertIsDefined(state.activeReport?.pages);

            state.activeReport.pages = state.activeReport.pages.map((p) => {
                if (p.id === state.activeReportPageId) {
                    delete p.components[action.payload.id];

                    return {
                        ...p,
                        components: {
                            ...p.components
                        }
                    };
                }
                return p;
            });
        },
        setActiveReportPageLayout: (state, action: PayloadAction<Layout>) => {
            assertIsDefined(state.activeReport);

            state.activeReport.pages = state.activeReport.pages.map((p) =>
                p.id === state.activeReportPageId ? { ...p, layout: action.payload } : p
            );
            state.hasChangedUI = true;
        },
        setComponentToEditId: (state, action: PayloadAction<string | undefined>) => {
            state.componentToEditId = action.payload;
        },

        setReportTheme: (state, action: PayloadAction<Report['uiTheme']>) => {
            if (!state.activeReport) {
                return;
            }
            state.activeReport.uiTheme = action.payload;
            state.hasChangedUI = true;
        },
        setAppliedFilter: (state, action: PayloadAction<any>) => {
            const filterIndex = state.appliedFilters.findIndex(
                (f) => f.id === action.payload.id
            );
            if (filterIndex === -1) {
                state.appliedFilters.push(action.payload);
            } else {
                state.appliedFilters[filterIndex] = action.payload;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(api.endpoints.getReportBySlug.matchFulfilled, (state, action) => {
                state.activeReport = action.payload;
            })
            .addMatcher(
                api.endpoints.createReportPage.matchFulfilled,
                (state, action) => {
                    state.activeReport?.pages.push(action.payload);
                }
            )
            .addMatcher(
                api.endpoints.deleteReportPage.matchFulfilled,
                (state, action) => {
                    assertIsDefined(state.activeReport);

                    state.activeReport.pages = state.activeReport.pages.filter(
                        (p: any) => p.id !== action.payload.id
                    );
                }
            )
            .addMatcher(api.endpoints.updateReport.matchFulfilled, (state, action) => {
                state.activeReport = {
                    ...state.activeReport,
                    ...action.payload
                };
            })
            .addMatcher(api.endpoints.getReportPageFilters.matchPending, (state) => {
                state.pageFilters = {};
            })
            .addMatcher(
                api.endpoints.getReportPageData.matchFulfilled,
                (state, action) => {
                    state.pageData = {
                        ...state.pageData,
                        ...action.payload
                    };
                }
            )
            .addMatcher(
                api.endpoints.getReportPageFilters.matchFulfilled,
                (state, action) => {
                    state.pageFilters = action.payload;
                }
            )
            .addMatcher(api.endpoints.updatePageComponents.matchPending, (state) => {
                state.isPageUpdating = true;
            })
            .addMatcher(api.endpoints.updatePageComponents.matchFulfilled, (state) => {
                state.isPageUpdating = false;
            })
            // Update report page
            .addMatcher(api.endpoints.updateReportPage.matchPending, (state) => {
                state.isPageUpdating = true;
            })
            .addMatcher(
                api.endpoints.updateReportPage.matchFulfilled,
                (state, action) => {
                    assertIsDefined(state.activeReport);
                    state.activeReport = {
                        ...state.activeReport,
                        pages: state.activeReport.pages.map((p) =>
                            p.id === action.payload.id ? { ...p, ...action.payload } : p
                        )
                    };
                    state.isPageUpdating = false;
                }
            )
            // Duplicate report page
            .addMatcher(api.endpoints.duplicateReportPage.matchPending, (state) => {
                state.isPageUpdating = true;
            })
            .addMatcher(
                api.endpoints.duplicateReportPage.matchFulfilled,
                (state, action) => {
                    assertIsDefined(state.activeReport);
                    state.activeReport = {
                        ...state.activeReport,
                        pages: state.activeReport.pages.concat(action.payload)
                    };
                    state.isPageUpdating = false;
                }
            )
            // Upload image
            .addMatcher(api.endpoints.uploadReportImage.matchPending, (state) => {
                state.isPageUpdating = true;
            })

            .addMatcher(
                api.endpoints.uploadReportImage.matchFulfilled,
                (state, action) => {
                    assertIsDefined(state.activeReport);
                    state.activeReport = {
                        ...state.activeReport,
                        images: [
                            {
                                bucket: action.payload.bucket,
                                bucketPath: action.payload.bucketPath,
                                id: action.payload.id,
                                originalName: action.payload.originalName,
                                ownerModelId: action.payload.ownerModelId,
                                publicUrl: action.payload.publicUrl
                            },
                            ...state.activeReport.images,
                        ]
                    };
                    state.isPageUpdating = false;
                }
            );
    }
});

export const {
    setHasChangedUI,
    setActiveReportPageId,
    setActiveReportPages,
    setActiveReport,
    setActiveReportPageLayout,
    setComponentToEditId,
    setReportTheme,
    setAppliedFilter,
    setComponentConfig,
    setBulkComponentConfig,
    deleteComponent
} = bluePrintSlice.actions;

const selectorAppliedFilters = (state: RootState) => state.blueprint.appliedFilters;

const selectFilters = createSelector([selectorAppliedFilters], (filters) => {
    return filters.map((f) => {
        if (f.value?.start && f.value?.end) {
            return {
                ...f,
                value: {
                    start: new Date(f.value.start).getTime(),
                    end: new Date(f.value.end).getTime()
                }
            };
        }

        return f;
    });
});

export const selectAppliedFilters = (state: RootState) => {
    const filters = selectFilters(state);
    return filters;
};

export const selectHasChangedUI = (state: RootState) => state.blueprint.hasChangedUI;

export const selectActiveReportPageId = (state: RootState) =>
    state.blueprint.activeReportPageId;

export const selectReportTheme = (state: RootState) => {
    return state.blueprint.activeReport?.uiTheme;
};

export const selectActiveReport = (state: RootState) => state.blueprint.activeReport;

export const useDefaultReportPageUISchema = async () => {
    return defaultTheme;
};

export const selectBlueprintState = (state: RootState) => state.blueprint;

export const selectThemeComponent = (componentType: ComponentType) => {
    const themeComponents = defaultTheme.components;

    if (!themeComponents) {
        return {};
    }

    // @ts-expect-error - we know that themeComponents[componentType] exists
    return themeComponents[componentType];
};

export const selectComponentData = (state: RootState, componentId: string) => {
    return state.blueprint.pageData[componentId];
};

export const selectComponent = (state: RootState, componentId: string) => {
    const activePage = state.blueprint.activeReport?.pages?.find(
        (p) => p.id === state.blueprint.activeReportPageId
    );

    return activePage?.components[componentId];
};

export const selectComponentById = memoize((componentId: string) => {
    return createSelector(
        [
            (state: RootState) => state.blueprint.activeReport?.pages,
            (state: RootState) => state.blueprint.activeReportPageId
        ],
        (pages, activeReportPageId) => {
            const page = pages?.find((p) => p.id === activeReportPageId);
            return page?.components[componentId];
        }
    );
});

export const selectComponentToEdit = (state: RootState) => {
    if (!state.blueprint.componentToEditId) return undefined;

    const reportPage = state.blueprint.activeReport?.pages?.find(
        (p) => p.id === state.blueprint.activeReportPageId
    );

    return reportPage?.components[state.blueprint.componentToEditId];
};

export const selectActiveReportPage = (state: RootState) => {
    return state.blueprint.activeReport?.pages?.find(
        (p) => p.id === state.blueprint.activeReportPageId
    );
};

export const selectDateRangeComponent = (state: RootState) => {
    const activePage = state.blueprint.activeReport?.pages?.find(
        (p) => p.id === state.blueprint.activeReportPageId
    );

    const components = activePage?.components;

    return Object.values(components ?? {}).find(
        (component) => component.type === ComponentType.DATE_RANGE
    );
};

export const selectComponents = (state: RootState) => {
    return state.blueprint.activeReport?.pages?.find(
        (p) => p.id === state.blueprint.activeReportPageId
    )?.components;
};

export const transformOrderOfPages = (
    pages: ReportPage[],
    slugsInNewOrder: { slug: string; icon: unknown }[]
) => {
    return slugsInNewOrder.map((themePage) => {
        const page = pages.find((page) => page.slug === themePage?.slug);
        return { ...page, navIcon: themePage.icon };
    });
};

export const selectReportPageFilters = (state: RootState) => {
    return state.blueprint.pageFilters;
};

export const selectConnectedSources = (state: RootState) => {
    return state.blueprint.activeReport?.connectedSources;
};

const getActiveReport = (state: RootState) => state.blueprint.activeReport;
const getActiveReportPageId = (state: RootState) => state.blueprint.activeReportPageId;

export const selectActiveReportPageCrumbProps = createSelector(
    [getActiveReport, getActiveReportPageId],
    (activeReport, activeReportPageId) => {
        const activeReportTitle = activeReport?.title;
        const activeReportPagedisplayName = activeReport?.pages?.find(
            (p) => p.id === activeReportPageId
        )?.displayName;

        return { title: activeReportTitle, displayName: activeReportPagedisplayName };
    }
);

export const selectPageThemeComponents = () => {
    const themeComponents = defaultTheme.components;

    return themeComponents;
};

export default bluePrintSlice.reducer;
