import { CSSProperties } from 'react';
import { ColumnFormatType } from 'common/enums';
import { ReturnedDataFieldConfig } from 'common/types';

export enum ReportComponentType {
    PIE_CHART = 'PIE_CHART',
    DONUT_CHART = 'DONUT_CHART',
    LINE_CHART = 'LINE_CHART',
    TABLE = 'TABLE',
    SINGLE_VALUE = 'SINGLE_VALUE',
    BRAND_LOGO = 'BRAND_LOGO',
    FILTER = 'FILTER',
    HEADER = 'HEADER',
    NAVIGATION_MENU = 'NAVIGATION_MENU'
}

export type ValueFormatUnitType = {
    [ColumnFormatType.CURRENCY]: 'EUR' | 'USD';
    [ColumnFormatType.DATE]: 'DD/MM/YYYY' | 'MM/DD/YYYY';
};

export type SingleValueData = {
    label: string;
    value: number;
    type: ColumnFormatType;
    format?: string;
    tooltip?: string;
};

export type ChartData = {
    label: string;
    data: { name: string; value: number }[];
    type:
        | ColumnFormatType.CURRENCY
        | ColumnFormatType.NUMBER
        | ColumnFormatType.PERCENTAGE;
    format?: string;
};
export type LineChartDataType = Record<string, string | number>;

export type LineChartData = {
    label?: string;
    data: LineChartDataType[];
    xAxisKey: keyof LineChartDataType;
    yAxisKeys: (keyof LineChartDataType)[];
    type:
        | ColumnFormatType.CURRENCY
        | ColumnFormatType.NUMBER
        | ColumnFormatType.PERCENTAGE;
    format?: string;
};

export type TableColumn = {
    title: string;
    accessor: string;
    type: ColumnFormatType;
    isMetric?: boolean;
    format?: string;
    tooltip?: string;
    decimalPlaces?: number | null;
};

export type TableRow = Record<string, unknown>;

export type TableSummaryRow = {
    accessor: string;
    value?: unknown;
}[];

export type TableData = {
    message?: {
        text: string;
        type: 'info' | 'warning' | 'error';
    };
    label?: string;
    columns: ReturnedDataFieldConfig[];
    rows: TableRow[];
    summary?: TableSummaryRow;
};

export type ReportComponentData = {
    [ReportComponentType.PIE_CHART]: ChartData;
    [ReportComponentType.DONUT_CHART]: ChartData;
    [ReportComponentType.LINE_CHART]: LineChartData;
    [ReportComponentType.TABLE]: TableData;
    [ReportComponentType.SINGLE_VALUE]: SingleValueData;
    [ReportComponentType.BRAND_LOGO]: Record<string, unknown>;
    [ReportComponentType.FILTER]: Record<string, unknown>;
    [ReportComponentType.HEADER]: Record<string, unknown>;
    [ReportComponentType.NAVIGATION_MENU]: Record<string, unknown>;
};

export type ReportComponent<T extends keyof ReportComponentData> = {
    title?: string;
    type: T;
    data: ReportComponentData[T];
};

export type ReportViewSectionRow = {
    title?: string;
    components: ReportComponent<keyof ReportComponentData>[];
};

export type Icon = 'meta' | 'googleAds';

export type ReportViewHeader = {
    title: string;
    subtitle: string;
};

export enum ReportViewSourceType {
    META = 'META',
    GOOGLE = 'GOOGLE',
    DV360 = 'DV360'
}

export enum ReportFilterTypes {
    DATE_RANGE = 'DATE_RANGE',
    SELECT = 'SELECT',
    MULTI_SELECT = 'MULTI_SELECT'
}

export type DateRangeFilter = {
    id: string;
    label: string;
    type: ReportFilterTypes.DATE_RANGE;
    from: string;
    to: string;
};

export type SelectFilter = {
    id: string;
    label: string;
    type: ReportFilterTypes.SELECT;
    values: { label: string; value: string }[];
};

export type MultiSelectFilter = {
    id: string;
    label: string;
    type: ReportFilterTypes.MULTI_SELECT;
    values: { label: string; value: string }[];
};
export type ReportViewFilter = DateRangeFilter | SelectFilter | MultiSelectFilter;
export type ReportViewFilters = (DateRangeFilter | SelectFilter | MultiSelectFilter)[];

export type ReportView = {
    id: number;
    slug: string;
    sourceType: ReportViewSourceType;
    label: string;
    icon: Icon | string;
    header: ReportViewHeader;
    filters: ReportViewFilters;
    rows: ReportViewSectionRow[];
};

export type ReportViews = Pick<ReportView, 'id' | 'label' | 'slug' | 'sourceType'>[];

export enum ReportTemplateName {
    BILLA = 'BILLA',
    COOP = 'COOP',
    GIVET = 'GIVET'
}

export type ReportResponse = {
    report: {
        slug: string;
    };
    reportViews: ReportViews;
    templateName: string;
    theme: AppTheme;
    activeReportView: ReportView;
};

// Theme
export type Color = string;
export type ThemeColorType =
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'light'
    | 'dark'
    | Color;
export type ThemeColors = Record<ThemeColorType, Color>;

export type ThemePalletType = 'default' | string;

export type ThemePallets = Record<ThemePalletType, Color[]>;

export type ChartStyle = {
    labelColor: ThemeColorType;
    pallet: ThemePalletType;
    innerRadius?: number;
    outerRadius?: number;
    showInnerLabels?: boolean;
    legendSymbol?: 'square' | 'circle';
    legendAlign?: 'left' | 'right';
    legendTop?: number;
};

export type TableStyle = {
    header: {
        backgroundColor: ThemeColorType;
        borderColor: ThemeColorType;
        textColor: ThemeColorType;
    };
    row: {
        backgroundColor: ThemeColorType;
        borderColor: ThemeColorType;
        textColor: ThemeColorType;
        hoverColor: ThemeColorType;
    };
    alternateRow: {
        backgroundColor: ThemeColorType;
        borderColor: ThemeColorType;
        textColor: ThemeColorType;
    };
    footer: {
        backgroundColor: ThemeColorType;
        borderColor: ThemeColorType;
        textColor: ThemeColorType;
    };
};

export type SingleValueStyle = {
    labelColor: ThemeColorType;
    valueColor: ThemeColorType;
    backgroundColor: ThemeColorType;
};

export type BrandLogoStyle = CSSProperties;

export type FilterStyle = {
    optionBackgroundColor: ThemeColorType;
    accentColor: ThemeColorType;
    headerColor: ThemeColorType;
    borderColor: ThemeColorType;
    backgroundColor: ThemeColorType;
    color: ThemeColorType;
    multiValueBackgroundColor: ThemeColorType;
    multiValueRemoveBackgroundColor: ThemeColorType;
    multiValueRemoveColor: ThemeColorType;
    multiValueFontWeight: CSSProperties['fontWeight'];
    hoverBackgroundColor: ThemeColorType;
    submitButtonBackgroundColor: ThemeColorType;
    hoverSubmitButtonBackgroundColor: ThemeColorType;
    rangeBackgroundColor: ThemeColorType;
    rangeColor: ThemeColorType;
};

export type HeaderStyle = {
    titleColor: ThemeColorType;
    subtitleColor: ThemeColorType;
};

export type NavigationMenuStyle = {
    linkColor?: ThemeColorType;
    linkHoverColor?: ThemeColorType;
    backgroundColor?: ThemeColorType;
    borderRight?: CSSProperties['border'];
};

export type ReportComponentStyleType =
    | ChartStyle
    | TableStyle
    | SingleValueStyle
    | BrandLogoStyle
    | FilterStyle
    | HeaderStyle
    | NavigationMenuStyle;

export type ReportComponentStyle = {
    [ReportComponentType.PIE_CHART]: ChartStyle;
    [ReportComponentType.DONUT_CHART]: ChartStyle;
    [ReportComponentType.LINE_CHART]: ChartStyle;
    [ReportComponentType.TABLE]: TableStyle;
    [ReportComponentType.SINGLE_VALUE]: SingleValueStyle;
    [ReportComponentType.BRAND_LOGO]: Record<string, unknown>;
    [ReportComponentType.FILTER]: FilterStyle;
    [ReportComponentType.HEADER]: HeaderStyle;
    [ReportComponentType.NAVIGATION_MENU]: NavigationMenuStyle;
};

export type AppTheme = {
    themeName: string;
    themeStyles: {
        themeColors: ThemeColors;
        pallets: ThemePallets;
    };
    componentsStyles: ReportComponentStyle;
};

export const valueIsNumeric: Record<ColumnFormatType, boolean> = {
    [ColumnFormatType.CURRENCY]: true,
    [ColumnFormatType.NUMBER]: true,
    [ColumnFormatType.PERCENTAGE]: true,
    [ColumnFormatType.DATE]: false,
    [ColumnFormatType.STRING]: false,
    [ColumnFormatType.AD_PREVIEW]: false,
    [ColumnFormatType.LINK]: false,
    [ColumnFormatType.WEEK]: false
};

// BLUEPRINT TYPES
export type BlueprintProperies<T extends string> = Record<T, CSSProperties>;

export type BlueprintDatePickerProperties = {
    buttonStyle: CSSProperties;
    popoverContentStyle: CSSProperties;
    rangeColors: string[];
    staticRangeLabelStyle: CSSProperties;
    pickerStyle: CSSProperties;
    omitPickerStyle: CSSProperties;
    submitButtonStyle: CSSProperties;
    labelStyle: CSSProperties;
    label: string;
    placeholder: string;
    showCalendarIcon?: boolean;
    showDropdownIndicator?: boolean;
    text?: string;
    iconStyle?: CSSProperties;
};

export type BlueprintMultiSelectProperties = {
    style?: CSSProperties;
    labelStyle?: CSSProperties;
    controlStyle?: CSSProperties;
    indicatorSeparatorStyle?: CSSProperties;
    dropdownIndicatorStyle?: CSSProperties;
    optionStyle?: CSSProperties;
    menuListStyle?: CSSProperties;
    placeholderStyle?: CSSProperties;
    multiValueLabelStyle?: CSSProperties;
    multiValueStyle?: CSSProperties;
    multiValueRemoveStyle?: CSSProperties;
    clearIndicatorStyle?: CSSProperties;
    valueContainerStyle?: CSSProperties;
    singleValueStyle?: CSSProperties;
    menuStyle?: CSSProperties;
    label?: string;
    placeholder?: string;
};

export type BlueprintTableProperties = {
    tableStyle: CSSProperties;
    labelStyle: CSSProperties;
    headerStyle: BlueprintProperies<'tr' | 'th' | 'cell'>;
    bodyStyle: {
        tr: BlueprintProperies<'_even' | '_odd'> & CSSProperties;
        td: CSSProperties;
    };
    footerStyle: BlueprintProperies<'tr' | 'td' | 'cell'>;
    cell: CSSProperties;
    activeCell: CSSProperties;
    headerCell: CSSProperties;
    activeHeaderCell: CSSProperties;
    idCell: CSSProperties;
    emptyTableStyle: BlueprintProperies<'tr' | 'td' | 'text'>;
    cardStyle: CSSProperties;
    paginationButtonsStyle: BlueprintProperies<
        | 'stack'
        | 'rowsCount'
        | 'iconBackButton'
        | 'backButton'
        | 'iconForwardButton'
        | 'forwardButton'
    >;
    modalContentStyle: CSSProperties;
    modalOverlayStyle: CSSProperties;
    modalBodyStyle: CSSProperties;
    component: {
        paginationLayout: 'top' | 'bottom';
        pageSize: number;
        embedded: {
            title: string;
        };
        rowNumbers?: boolean;
    };
    paginationRowsPerPageStyle?: BlueprintMultiSelectProperties & {
        label: string;
        labelStyle: CSSProperties;
    };
    
};

export type ComponentUI = {
    id: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    properties: any;
    variant?: string;
    height: number;
    width: number;
    zIndex?: number;
};

type Column = {
    id: string;
    order: number;
    size: number;
    properties: Record<string, unknown>;
    components: string[];
};

type Row = {
    id: string;
    order: number;
    properties: Record<string, unknown>;
    cols: Column[];
};

type Block = {
    id: string;
    gridArea: string;
    properties: Record<string, unknown>;
    rows: Row[];
};

type Layout = {
    gridTemplateColumns: string;
    gridTemplateRows: string;
    gridTemplateAreas: string;
    columnGap: string;
    rowGap: string;
};

export type LinearGradientStop = {
    offset: string;
    stopColor: string;
    stopOpacity: number;
};

export type LinearGradient = {
    angle?: number;
    x1: string;
    y1: string;
    x2: string;
    y2: string;
    legendSymbol: 'square' | 'circle';
    legendSymbolFillColor: CSSProperties['color'];
    legendSymbolTextColor: CSSProperties['color'];
    stops: LinearGradientStop[];
};

export type UISchema = {
    layout: Layout;
    blocks: Block[];
    components: ComponentUI[];
};
