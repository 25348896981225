import { useGetReportPageDataMutation, useGetReportPageFiltersMutation } from "@api";
import { useAppSelector } from "@hooks";
import { createSelector } from "@reduxjs/toolkit";
import { Component } from "common/types";
import { useEffect } from "react";
import { useCanvas } from "src/blueprint/pages/editor/EditorContext";
import KeyboardShortcutsModal from "src/components/KeyboardShortcutsModal";
import { selectActiveReportPage, selectAppliedFilters, selectDateRangeComponent } from "src/redux/features/blueprint/bluePrintSlice";
import Canvas from "src/templates/blueprint/Canvas/Canvas";
import UIComponent from "src/templates/blueprint/UIComponent";
import { getDataComponents, getFilterComponents } from "src/templates/blueprint/utils";

const CanvasReport = () => {
    const { state, actions } = useCanvas();
    const activeReportPage = useAppSelector(selectActiveReportPage);
    const [fetchPageData] = useGetReportPageDataMutation();
    const [fetchPageFilterOptions] = useGetReportPageFiltersMutation();
    const dateRangeFilter = useAppSelector(selectDateRangeComponent);

    const selectMemoStart = createSelector(
        [selectAppliedFilters],
        (filters) => filters?.find((f: any) => f.id === dateRangeFilter?.id)?.value?.start
    );
    const selectMemoEnd = createSelector(
        [selectAppliedFilters],
        (filters) => filters?.find((f: any) => f.id === dateRangeFilter?.id)?.value?.end
    );
    const startDate = useAppSelector((state) => selectMemoStart(state));
    const endDate = useAppSelector((state) => selectMemoEnd(state));

    useEffect(() => {
        if (activeReportPage?.id && state) {
            fetchPageData({
                reportPageId: activeReportPage.id,
                componentIds: getDataComponents(activeReportPage.components).map(
                    (c) => c.id
                ),
                filterValues: [
                    {
                        id: dateRangeFilter?.id,
                        value: {
                            start: startDate,
                            end: endDate
                        }
                    }
                ],
                isSharedReport: state.useSharedReportAPI
            });
        }
    }, [activeReportPage?.id]);

    useEffect(() => {
        if (activeReportPage && Boolean(startDate) && Boolean(endDate)) {
            fetchPageFilterOptions({
                reportPageId: activeReportPage.id,
                filterIds: getFilterComponents(activeReportPage.components).map(
                    (f) => f.id
                ),
                dateRange: {
                    start: startDate,
                    end: endDate
                },
                isSharedReport: state.useSharedReportAPI
            });
        }
    }, [activeReportPage?.id]);

    const components: Component[] = Object.values(activeReportPage?.components ?? {});

    return <>{activeReportPage && (
        <Canvas layout={activeReportPage.layout}>
            {components.map((item) => (
                <UIComponent key={item.id} component={item} />
            ))}
        </Canvas>
    )}
        <KeyboardShortcutsModal /></>
}

export default CanvasReport;