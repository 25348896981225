import { Box, Flex, Text } from '@chakra-ui/react';
import ReactSelect, { components, ControlProps, OptionProps } from 'react-select';
import { ColumnFormatType, ComponentType } from 'common/enums';
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import { useAppSelector } from 'src/hooks/redux';
import { selectComponentData } from 'src/redux/features/blueprint/bluePrintSlice';
import { formatGenericValue } from 'src/utils';
import { useDefaultData } from '../defaultData';
import GoCard from './GoCard';
import { useState } from 'react';
import { DateTime } from 'luxon';
import { ComponentUI } from '@types';
import { ReturnedDataFieldConfig } from 'common/types';

// Define types for options and data entries
type OptionType = {
    value: string;
    label: string;
    color: string;
};

type DataEntryType = {
    [key: string]: any;
};

// Custom control component for ReactSelect
const Control = ({ children, ...props }: ControlProps<OptionType>) => {
    const color = props.getValue()?.[0]?.color;
    return (
        <components.Control {...props}>
            <Box
                ml="0.75rem"
                w="16px"
                h="16px"
                backgroundColor={color}
                borderRadius="4px"
            />
            {children}
        </components.Control>
    );
};

// Custom option component for ReactSelect
const Option = ({ children, ...props }: OptionProps<OptionType>) => {
    return (
        <components.Option {...props}>
            <Flex role="group" gap={2} alignItems="center" pl="0.5rem">
                <Box
                    w="16px"
                    h="16px"
                    borderRadius="4px"
                    backgroundColor={props.data.color}
                />
                {children}
            </Flex>
        </components.Option>
    );
};

// Helper function to calculate the domain for Y-axis
const calculateDomain = (data: number[], paddingFactor = 0.15): [number, number] => {
    const maxValue = Math.max(...data);
    const minValue = Math.min(...data);
    const range = maxValue - minValue;
    const padding = range * paddingFactor;
    const domainMin = minValue - padding < 0 ? 0 : minValue - padding;
    const domainMax = maxValue + padding;
    return [domainMin, domainMax];
};

// Helper function to render Y-axis
const renderYAxis = (
    id: string | null,
    _: [number, number], // NOTE(jakub.bystrican) for now we will use ['auto', 'auto'] domain
    yAxisConfig: ReturnedDataFieldConfig,
    orientation: 'left' | 'right',
    properties: any,
    fill?: string
) => {
    const tick = {
        fill: fill,
        ...properties?.tick
    };

    return (
        <YAxis
            yAxisId={id ? id : undefined}
            domain={['auto', 'auto']}
            tickCount={6}
            tickFormatter={(value) => {
                if (!yAxisConfig) {
                    return value;
                }

                return formatGenericValue(value, yAxisConfig.type, {
                    ...yAxisConfig,
                    compactDisplay: 'short'
                });
            }}
            orientation={orientation}
            {...properties}
            tick={tick}
        />
    );
};

// Helper function to render a Line component
const renderLine = (
    key: string,
    name: string,
    stroke: string,
    fill: string,
    yAxisId: string | null,
    properties?: any
) => {
    return (
        <Line
            key={key}
            type={properties?.type ? properties.type : 'monotone'}
            dataKey={key}
            dot={properties?.dot ?? false}
            name={name}
            stroke={stroke}
            fillOpacity={1}
            fill={fill}
            yAxisId={yAxisId ? yAxisId : undefined}
        />
    );
};

// Helper function to render the custom select dropdowns
const renderReactSelect = (
    options: OptionType[],
    selectedValue: string | undefined | null,
    setSelectedValue: React.Dispatch<React.SetStateAction<string | undefined | null>>
) => (
    <ReactSelect
        isMulti={false}
        options={options}
        onChange={(selectedOption) => {
            if (selectedOption) {
                setSelectedValue(selectedOption.value);
            } else {
                setSelectedValue(null);
            }
        }}
        value={options.find((option) => option.value === selectedValue)}
        styles={{
            control: (base) => ({
                ...base,
                width: '12rem'
            }),
            option: (base, props_) => ({
                ...base,
                color: props_.data.color,
                backgroundColor: props_.isSelected ? '#f5f5f5' : 'white',
                '&:hover': { backgroundColor: '#f5f5f5' }
            }),
            indicatorSeparator: (base) => ({ ...base, display: 'none' })
        }}
        placeholder="Select metric"
        components={{ Control, Option }}
    />
);

// Tooltip rendering function
const renderTooltipContent = (
    o: any,
    xAxisKey: string,
    xAxisFieldConfig: ReturnedDataFieldConfig,
    yAxisConfig: ReturnedDataFieldConfig,
    yAxisConfig2: ReturnedDataFieldConfig,
    tooltipStyle: any
) => {
    if (!o) return null;

    const { payload } = o;
    if (!payload || !payload.length) return null;

    const xValue = payload[0]?.payload?.[xAxisKey];
    let xValueText =
        xValue && xAxisFieldConfig?.type === ColumnFormatType.WEEK ? (
            <Text>
                Week {DateTime.fromISO(xValue).toFormat('dd.MM.yyyy')} -{' '}
                {DateTime.fromISO(xValue).endOf('week').toFormat('dd.MM.yyyy')}
            </Text>
        ) : null;

    if (xValue && xAxisFieldConfig?.type === ColumnFormatType.DATE) {
        xValueText = <Text>{DateTime.fromISO(xValue).toFormat('d MMM yyyy')}</Text>;
    }

    if (payload.length === 1) {
        return (
            <Box
                fontSize="12px"
                boxShadow="0px 1.79px 8.93px 0px #7C8DB51F"
                style={tooltipStyle?.style}
            >
                {xValueText}
                <Text style={tooltipStyle?.textStyle}>
                    {`${payload[0].name}: ${formatGenericValue(payload[0].value, yAxisConfig?.type, yAxisConfig)}`}
                </Text>
            </Box>
        );
    }

    return (
        <Box
            fontSize="12px"
            boxShadow="0px 1.79px 8.93px 0px #7C8DB51F"
            style={tooltipStyle?.style}
        >
            {xValueText}
            <ul
                className="list"
                style={{
                    listStyleType: 'none'
                }}
            >
                {payload.map((item: any, index: number) => {
                    const filedConfig =
                        item.dataKey === yAxisConfig?.accessor
                            ? yAxisConfig
                            : yAxisConfig2;

                    const formattedValue = filedConfig?.type
                        ? formatGenericValue(item.value, filedConfig.type, filedConfig)
                        : item.value;

                    return (
                        <li key={`item-${index}`} style={tooltipStyle?.textStyle}>
                            {`${item.name}: ${formattedValue}`}
                        </li>
                    );
                })}
            </ul>
        </Box>
    );
};

const WITH_TWO_Y_AXES = false;
const WITH_TWO_METRICS = false;
const DEFAULT_PADDING_FACTOR = 0.15;
const DEFAULT_MARGIN = { left: 20, right: 20, top: 20, bottom: 20 };
const DEFAULT_LEGEND_PROPERTIES = {
    align: 'left',
    verticalAlign: 'top'
};

const GoLineChart = ({ properties, id, height }: ComponentUI) => {
    const { state } = useCanvas();
    const isSharedReport = !state.useEditorReport;
    const componentData = useAppSelector((state) => selectComponentData(state, id));
    const defaultData = useDefaultData(ComponentType.LINE_CHART);

    let data = componentData ?? {};
    if ((componentData?.error || componentData === undefined) && !isSharedReport) {
        data = defaultData;
    }

    const twoYAxes = properties?.component?.twoYAxes ?? WITH_TWO_Y_AXES;

    const metricSelection = properties?.component?.metricSelection ?? WITH_TWO_METRICS;

    const xAxisKey = data?.xAxisKey;
    const [selectedYAxisKey, setSelectedYAxisKey] = useState<string | undefined | null>();
    const [selectedYAxisKey2, setSelectedYAxisKey2] = useState<
        string | undefined | null
    >();

    const yAxisKey =
        selectedYAxisKey === undefined ? data?.yAxisKeys?.[0] : selectedYAxisKey;
    const yAxisKey2 =
        selectedYAxisKey2 === undefined ? data?.yAxisKeys?.[1] : selectedYAxisKey2;

    const selectedIndex =
        data?.yAxisKeys?.findIndex((key: string) => key === yAxisKey) ?? 0;
    const selectedIndex2 = data?.yAxisKeys?.findIndex((key: string) => key === yAxisKey2);

    const xAxisFieldConfig = data?.fieldConfigs?.[xAxisKey];
    const yAxisConfig = data?.fieldConfigs?.[yAxisKey];
    const yAxisConfig2 = data?.fieldConfigs?.[yAxisKey2];

    const xFormatType = xAxisFieldConfig?.type;
    const selectedData = data?.data?.map((entry: DataEntryType) => entry[yAxisKey]);
    const selectedData2 = data?.data?.map((entry: DataEntryType) => entry[yAxisKey2]);

    const paddingFactor = properties?.component?.paddingFactor ?? DEFAULT_PADDING_FACTOR;

    const customizedDomain = calculateDomain(selectedData ?? [], paddingFactor);
    const customizedDomain2 = calculateDomain(selectedData2 ?? [], paddingFactor);

    const formatXTicks = (value: any) =>
        formatGenericValue(value, xFormatType, {
            ...xAxisFieldConfig,
            dateFormat: 'dd.MM'
        });

    const fill =
        properties.component?.pallet?.[
            selectedIndex % properties.component.pallet.length
        ] || 'gray';
    const fill2 =
        properties.component?.pallet?.[
            selectedIndex2 % properties.component.pallet.length
        ] || 'gray';

    const name = yAxisConfig?.title;
    const name2 = yAxisConfig2?.title;

    const legendProps = properties?.component?.legend ?? {};

    const renderLegend = () => {
        const options = data?.yAxisKeys?.map((key: string, index: number) => ({
            value: key,
            label: data?.fieldConfigs?.[key]?.title,
            color: properties.component?.pallet?.[
                index % properties.component.pallet.length
            ]
        }));

        if (metricSelection) {
            return (
                <Flex maxW="30rem" gap="0.5rem">
                    {renderReactSelect(options, yAxisKey, setSelectedYAxisKey)}
                    {twoYAxes &&
                        renderReactSelect(options, yAxisKey2, setSelectedYAxisKey2)}
                </Flex>
            );
        }

        return (
            <Flex gap="1rem">
                {options.map((option: any) => (
                    <Flex
                        gap="0.25rem"
                        alignItems="center"
                        style={legendProps?.listStyle}
                    >
                        <Box
                            w="8px"
                            h="8px"
                            borderRadius="50%"
                            backgroundColor={option.color}
                            style={legendProps?.symbolStyle}
                        />
                        <Text fontSize="11px" style={legendProps?.textStyle}>
                            {option.label}
                        </Text>
                    </Flex>
                ))}
            </Flex>
        );
    };

    const leftYAxisId = twoYAxes ? 'left' : null;
    const rightYAxisId = twoYAxes ? 'right' : null;

    const margin = {
        left: properties?.component?.margin?.left ?? DEFAULT_MARGIN.left,
        right: properties?.component?.margin?.right ?? DEFAULT_MARGIN.right,
        top: properties?.component?.margin?.top ?? DEFAULT_MARGIN.top,
        bottom: properties?.component?.margin?.bottom ?? DEFAULT_MARGIN.bottom
    };

    const legendAlign = legendProps.align ?? DEFAULT_LEGEND_PROPERTIES.align;

    const legendVerticalAlign =
        legendProps.verticalAlign ?? DEFAULT_LEGEND_PROPERTIES.verticalAlign;

    const legendWrapperStyle = legendProps.wrapperStyle ?? {
        paddingTop: legendVerticalAlign === 'bottom' ? '2rem' : '0',
        paddingBottom: legendVerticalAlign === 'top' ? '2rem' : '0'
    };

    return (
        <GoCard
            width={'100%'}
            height={height}
            cardStyle={properties?.cardStyle}
        >
            <ResponsiveContainer>
                {data?.data === undefined ? (
                    <Flex
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        textAlign="center"
                    >
                        For the selected period and campaigns <br /> there is no data
                    </Flex>
                ) : (
                    <LineChart data={data?.data} margin={margin}>
                        <Legend
                            align={legendAlign}
                            verticalAlign={legendVerticalAlign}
                            wrapperStyle={legendWrapperStyle}
                            content={renderLegend}
                        />

                        <CartesianGrid {...properties?.component?.cartesianGrid} />
                        <XAxis
                            dataKey={xAxisKey}
                            tickFormatter={formatXTicks}
                            {...properties?.component?.xAxis}
                        />
                        {yAxisKey &&
                            renderYAxis(
                                leftYAxisId,
                                customizedDomain,
                                yAxisConfig,
                                'left',
                                properties?.component?.leftYAxis,
                                fill
                            )}
                        {twoYAxes &&
                            renderYAxis(
                                'right',
                                customizedDomain2,
                                yAxisConfig2,
                                'right',
                                properties?.component?.rightYAxis,
                                fill2
                            )}
                        <Tooltip
                            formatter={(value) =>
                                formatGenericValue(
                                    value,
                                    properties?.component?.formatType
                                )
                            }
                            content={(o) =>
                                renderTooltipContent(
                                    o,
                                    xAxisKey,
                                    xAxisFieldConfig,
                                    yAxisConfig,
                                    yAxisConfig2,
                                    properties?.component?.tooltip
                                )
                            }
                        />
                        {yAxisKey &&
                            renderLine(
                                yAxisKey,
                                name,
                                fill,
                                fill,
                                leftYAxisId,
                                properties.component?.line
                            )}
                        {yAxisKey2 &&
                            twoYAxes &&
                            renderLine(
                                yAxisKey2,
                                name2,
                                fill2,
                                fill2,
                                rightYAxisId,
                                properties?.component?.line
                            )}
                    </LineChart>
                )}
            </ResponsiveContainer>
        </GoCard>
    );
};

export default GoLineChart;
